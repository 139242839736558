@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-dynamic-subset.css");

body {
  margin: 0;
}

input {
  padding: 0;
}

input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}

button {
  outline: none;
}

.title {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 14px 0 0 16px;
}

.navbar {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
}

.navbar .nav {
  height: 56px;
  float: left;
  width: calc(100% / 3);
  margin: 0;
  text-align: center;
  font-size: 13px;
}

.navbar .nav .icon {
  width: 33px;
  height: 33px;
  margin: auto;
}

.navbar .nav .icon.root {
  background: url(/src/img/home.png) no-repeat center;
}

.navbar .nav .icon.fitting {
  background: url(/src/img/search.png) no-repeat center;
}

.navbar .nav .icon.guide {
  background: url(/src/img/book.png) no-repeat center;
}

.navbar .nav .icon.gym {
  background: url(/src/img/message-circle.png) no-repeat center;
}

.navbar .nav.active .icon.root {
  background: url(/src/img/home-filled.png) no-repeat center;
}

.navbar .nav.active .icon.fitting {
  background: url(/src/img/search-filled.png) no-repeat center;
}

.navbar .nav.active .icon.guide {
  background: url(/src/img/book-filled.png) no-repeat center;
}

.navbar .nav.active .icon.gym {
  background: url(/src/img/message-circle-filled.png) no-repeat center;
}

.navbar .nav .icon-text {
  color: #8e8e93;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

.navbar .nav.active .icon-text {
  color: #26b345;
}

.home {
  height: 90%;
  width: 100%;
  overflow-x: hidden;
  position: fixed;
}

.home > .title {
  font-family: 'Roboto Condensed';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.home > .logo-area {
  top: 0;
  
}

.home > .logo-area > img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.home > .logo-area .logo {
  position: relative;
  background-color: gray;
  transform: translateX(-50%);
  left: 50%;
  top: 45px;
  width: 142px;
  height: 142px;
}
.home > .news {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home > .news > img {
  margin-top: 40px;
  width: 100%;
}

.fitting {
  font-family: "Pretendard";
}

.fitting .search-mode {
  position: relative;
  height: 36px;
  margin: 22px;
}

.fitting input {
  border: 1px solid #f1f1f1;
  border-radius: 20px;
  width: calc(100% - 55px);
  height: 100%;
  padding-left: 45px;
  font-family: "Pretendard";
  background: url(/src/img/search.png) no-repeat 12px center,
    rgba(116, 116, 128, 0.08);
}

.fitting .area-list {
  margin-bottom: 100px;
}

.fitting .area {
  padding: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.fitting button {
  float: right;
  right: 19px;
  width: 20px;
  height: 20px;
  padding: 0;
  border: 0;
}

.fitting button.fold {
  background: url(/src/img/fold.png) no-repeat center;
}

.fitting button.unfold {
  background: url(/src/img/unfold.png) no-repeat center;
}

.place-list {
  padding: 16px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.place-list::-webkit-scrollbar {
  display: none;
}

.place-list .place {
  margin-left: 8px;
  width: 189px;
  height: 174px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  overflow: hidden;
  display: inline-block;
  position: relative;
}

.place-list .place .place-desc {
  margin-left: 12px;
  width: 189px;
  height: 174px;
  display: inline-block;
  white-space: nowrap;
  position: absolute;
}

.place-list .place .place-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 3px 0;
}

.place-list .place .place-line {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.place-list .place .place-address {
  margin-top: 3px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #92959c;
}

.fitting-search {
  font-family: "Pretendard";
}

.fitting-search .search-area {
  display: flex;
  margin: 22px 0 22px 16px;
}

.fitting-search .search-mode {
  position: relative;
  height: 34px;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
}

.fitting-search input {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 36px;
  padding: 0px 40px 0px 35px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #e5e5e8;
  -webkit-border-radius: 8px;
  border-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background-color: rgba(116, 116, 128, 0.08);
  font-family: "Pretendard";
}

.fitting-search .search-mode::before {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px;
  background-image: url(/src/img/search.png);
  content: "";
  top: 0px;
  left: 0px;
  width: 35px;
  height: 35px;
}

.fitting-search .fitting-close {
  padding: 0 24px 0 24px;
  border: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.3px;
  height: 36px;

  color: #767678;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/src/img/fitting-search-close.png);
  background-size: 20px;
  background-color: white;
}

.fitting-search .search-mode.text .fitting-close {
  background-size: 20px;
}

.fitting-search .fitting-clear {
  position: absolute;
  margin-right: 10px;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 11px;
  border: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/src/img/clear-input.png);
  background-size: 16px;
  background-color: transparent;
}

.fitting-search .search-item {
  position: relative;
  display: flex;
  margin: 12px 16px;
}

.fitting-search img {
  width: 96px;
  height: 60px;
  border-radius: 8px;
}

.fitting-search .search-desc {
  margin-left: 16px;
}

.fitting-search .search-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.fitting-search .search-line {
  margin-top: 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.fitting-search .search-address {
  margin-top: 5px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #92959c;
}

.sub-header {
  position: fixed;
  height: 52px;
  width: 100%;
}

.sub-header .back {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/src/img/chevron-left.png);
  background-size: 20px;
  background-color: transparent;
  width: 48px;
  height: 52px;
  border: 0;
}

.sub-header.scrolled .back {
  background-image: url(/src/img/chevron-left-black.png);
}

.sub-header.scrolled {
  background-color: white;
}

.sub-header .detail-title {
  display: none;
  padding: 0px 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sub-header.scrolled .detail-title {
  vertical-align: middle;
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding-top: 15px;
}

.sub-header.scrolled .complete {
  border: 0;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 16px;
  padding-right: 0px;
}

.fitting-detail {
  font-family: "Pretendard";
}

.fitting-detail .detail-thumb img {
  width: 100%;
}

.fitting-detail .detail-desc {
  margin: 24px 16px;
}

.fitting-detail .detail-desc .detail-title {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 8px;
}

.fitting-detail .detail-desc .detail-line {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: #64666b;
}

.fitting-detail .detail-desc .detail-address {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #92959c;
  margin-bottom: 8px;
}

.fitting-detail .detail-desc .detail-address span {
  margin-left: 24px;
}

.fitting-detail .detail-desc .detail-call {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #92959c;
  margin-bottom: 40px;
}

.fitting-detail .detail-desc .detail-call span {
  margin-left: 24px;
}

.fitting-detail .detail-desc .detail-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.fitting-detail .detail-address::before {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px;
  background-image: url(/src/img/map-pin.png);
  content: "";
  width: 20px;
  height: 20px;
}

.fitting-detail .detail-call::before {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px;
  background-image: url(/src/img/phone.png);
  content: "";
  width: 20px;
  height: 20px;
}

.guide {
  margin-bottom: 56px;
}

.guide .guide-box {
  text-align: center;
  margin-top: 50px;
  display: flex;
}

.guide .guide-box .rule {
  height: 100px;
  width: 50%;
  float: left;
}

.guide .guide-box .terms {
  height: 100px;
  width: 50%;
  float: right;
}

.guide .guide-box .text {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}

.guide .dc h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 6px 16px;
}

.guide .dc {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.guide .dc h2 {
  padding: 16px 0 0 16px;
}

.guide .dc button {
  float: right;
  right: 19px;
  width: 20px;
  height: 20px;
  padding: 0;
  border: 0;
}

.guide .dc button.fold {
  background: url(/src/img/fold.png) no-repeat center;
  height: 54px;
  line-height: 54px;
}

.guide .dc button.unfold {
  background: url(/src/img/unfold.png) no-repeat center;
  height: 54px;
  line-height: 54px;
}

.guide .dc .dc-title {
  padding: 0px 16px 0px 16px;
  height: 54px;
  line-height: 54px;
}

.guide .dc .dc-sub {
  background-color: rgba(116, 116, 128, 0.04);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 33px;
  height: 40px;
  vertical-align: center;
  line-height: 40px;
}

.layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
}

.layer .layer_inner {
  position: absolute;
  right: 0;
  left: 0;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  max-width: 512px;
  margin: 0 auto;
  align-items: center;
  padding: 16px 12px;
}

.layer .layer_inner .layer_area {
  overflow: hidden;
  background-color: #fff;
  -webkit-border-radius: 12px;
  border-radius: 20px;
  padding: 0px 20px 40px 20px;
}

.layer .layer_inner .layer_area .bar {
  background: rgba(120, 120, 128, 0.2);
  border-radius: 4px;
  width: 44px;
  height: 4px;
  transform: translateX(-50%);
  left: 50%;
  margin-top: 8px;
  position: absolute;
}

.layer .layer_inner .layer_area .title {
  margin: 36px 0 32px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.layer .layer_inner .layer_area .desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

/* .layer .layer_inner .div {
  width: 100%;
  min-height: 65px;
  padding: 14px 20px 12px;
  border: 0;
  text-align: left;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
} */

.rule .item-list {
  padding-top: 52px;
}

.rule .item {
  padding: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: center;
}

.rule .item span {
  width: 100%;
}

.rule .btn {
  float: right;
  width: 20px;
  height: 20px;
  padding: 0;
  border: 0;
  background: url(/src/img/chevron-right.png) no-repeat center 3px;
}

.rule-detail .content-area {
  padding-top: 52px;
}

.rule-detail .content-area .content {
  margin: 24px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.term .dc h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 6px 16px;
}

.term .dc {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.term .dc h2 {
  padding: 16px 0 0 16px;
}

.term .dc button {
  float: right;
  right: 19px;
  width: 20px;
  height: 20px;
  padding: 0;
  border: 0;
}

.term .dc button.fold {
  background: url(/src/img/fold.png) no-repeat center;
  height: 54px;
  line-height: 54px;
}

.term .dc button.unfold {
  background: url(/src/img/unfold.png) no-repeat center;
  height: 54px;
  line-height: 54px;
}

.term .dc .dc-title {
  padding: 0px 16px 0px 16px;
  height: 54px;
  line-height: 54px;
}

.term .dc .dc-sub {
  background-color: rgba(116, 116, 128, 0.04);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 33px;
  height: 40px;
  vertical-align: center;
  line-height: 40px;
}

.term .dc {
  padding-top: 52px;
}

.board {
  font-family: "Pretendard";
}

.board .notice {
  margin: 16px 12px;
  background: rgba(116, 116, 128, 0.04);
  border-radius: 12px;
  padding: 16px;
}

.board .notice .title {
  font-weight: 600;
  margin: 0 0 8px;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: #159531;
}

.board .notice .content {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  align-items: center;
}

.board .board-list {
  margin-bottom: 56px;
}
.board .board-item {
  padding: 16px;
  margin: 0;
}

.board .board-title {
  margin-bottom: 4px;
}

.board .board-item .user {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  align-items: center;
}

.board .board-item .time {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  align-items: center;
  color: #92959c;
  margin-left: 4px;
}

.board .board-item .board-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
}

.board .board-item .more {
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/src/img/Union.png);
  position: absolute;
  right: 16px;
  height: 21px;
  background-color: transparent;
  border: 0;
}

.board-add {
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/src/img/plus.png);
  position: absolute;
  background-size: 56px;
  background-color: transparent;
  border: 0;
  position: fixed;
  bottom: 68px;
  right: 14px;
  width: 56px;
  height: 56px;
  z-index: 10;
}

.alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
}

.alert .layer_inner {
  position: absolute;
  right: 0;
  left: 0;
  align-items: center;
  padding: 20px 15px 17px 24px;
  background-color: white;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  max-width: 250px;
  margin: 0 auto;
}

.alert .layer_area .alert_title {
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
}

.alert .layer_area .alert_desc {
  margin: 35px 0px 3px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.alert_task {
  height: 57px;
}

.alert .layer_area .alert_task .cancel {
  background-color: transparent;
  border: 0;
  padding: 0;
  position: absolute;
  bottom: 17px;
  right: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #26b345;
}

.board-write {
  font-family: "Pretendard";
}

.board-write .write-area {
  padding-top: 76px;
}

.board-write .write-title {
  border-bottom: 1px solid rgba(112, 112, 125, 0.1);
  padding: 0 16px 20px 16px;
}

.board-write .write-title input {
  border: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  font-family: "Pretendard";
  caret-color: #26b345;
}

.board-write textarea {
  font-family: "Pretendard";
}

.board-write .write-content {
  padding: 20px 16px 0 16px;
}
.board-write .write-content textarea {
  border: 0;
  width: 100%;
  height: 200px;
}

.help-button {
  background: url(/src/img/help.png) no-repeat center;
  top: 0px;
  right: 0px;
  margin: 16px 16px 0 0;
  padding: 0px;
  border: 0;
  width: 20px;
  height: 20px;
}

.help {
  font-family: "Pretendard";
}

.help .contents {
  padding-top: 52px;
}
.help .item-title {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  margin: 16px 0 16px 16px;
  color: #64666b;
}

.help .item-list {
  padding-bottom: 32px;
}
.help .item {
  padding: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.help .item span {
  width: 100%;
}

.help .btn {
  float: right;
  width: 20px;
  height: 20px;
  padding: 0;
  border: 0;
  background: url(/src/img/chevron-right.png) no-repeat center 3px;
}

.help-detail {
  font-family: "Pretendard";
}

.help-detail .content-area {
  padding-top: 76px;
  margin: 0px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
